import LocomotiveScroll from 'locomotive-scroll';

class DragScroller {
    private scroll: LocomotiveScroll;
    private container: HTMLElement;

    constructor(scrollContainer: HTMLElement) {
        this.container = scrollContainer;

        this.scroll = new LocomotiveScroll({
            el: this.container,
            direction: 'horizontal',
            smooth: true
        });
    }

}

export default DragScroller;