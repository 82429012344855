// Define the initial theme state
export let isLightTheme: boolean = true;

// Function to toggle the theme
export function toggleTheme(): void {
    isLightTheme = !isLightTheme;
    document.body.setAttribute("data-theme", isLightTheme ? "light" : "dark");
}

export const initializeThemeToggle = (): void => {
    // Get the sun checkbox element
    const sun: HTMLInputElement | null = document.getElementById("sun") as HTMLInputElement;
    
    // Ensure the element exists before accessing its properties
    if (sun !== null) {
        // console.log("sun exists");
        // Set its initial state based on the theme
        sun.checked = isLightTheme;
        
        // Add an event listener to toggle the theme when the checkbox changes
        sun.addEventListener("change", toggleTheme);
    }
}