// Loader.ts
import { gsap } from "gsap";
import LoadState from "./LoadState";

export default class Loader {
  private totalImages: number = 0;
  private loadedImages: number = 0;
  private loaderElement: HTMLElement | null;
  private loaderContainer: HTMLElement | null;
  private fakeLoadComplete: boolean = false; // Flag to simulate completion
  private onLoadComplete: (() => void) | null = null;
  private tl: gsap.core.Timeline;


  constructor() {
    this.loaderElement = document.getElementById('loader');
    this.loaderContainer = document.getElementById('loaderContainer');
    this.updateProgress(0); // Initialize progress at 0%
    this.tl = gsap.timeline();
  }

  setTotalImages(count: number): void {
    this.totalImages = count;
    this.loadedImages = 0;
  }

  imageLoaded(): void {
    this.loadedImages++;
    let progress = (this.loadedImages / this.totalImages) * 100;

    if (!this.fakeLoadComplete && progress >= 80) {
      this.fakeLoadComplete = true; // Mark fake completion
      this.updateProgress(100); // Update visual progress to 100%
      this.hideLoader(); // Hide loader when all images are actually loaded
    } 
    else if (!this.fakeLoadComplete) {
      this.updateProgress(progress);
    }

    if(progress === 100) {
      LoadState.getInstance().setFullyLoaded(true);
    }
  }

  updateProgress(progress: number): void {
    if (this.loaderElement) {
      gsap.to(this.loaderElement, { 
        duration: 2, 
        innerText: Math.round(progress),
        ease: "power3.out",
        onUpdate: () => {
          this.loaderElement!.textContent = `${Math.round(progress)}%`;
        }
      });
    }
  }

  setOnLoadComplete(callback: () => void): void {
    this.onLoadComplete = callback;
  }

  hideLoader(): void {
    this.tl
      .to(this.loaderElement, {
        y: '-100%',
        ease: "power3.out",
        duration: 1,
        delay: 1
      })
      .to(this.loaderElement, {
        autoAlpha: 0, // Fades out and sets display to none
        duration: .5,
        onComplete: () => {
          // Remove the loader container from the DOM here
          this.loaderContainer?.parentNode?.removeChild(this.loaderContainer);
          // Call the onLoadComplete callback, if any, to proceed with the app initialization
          if (this.onLoadComplete) {
            this.onLoadComplete();
          }
        }
      });
  }
}
