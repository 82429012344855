import { gsap } from 'gsap';
import ICursor from '../types/ICursor';

type MousePosition = {
    x: number;
    y: number;
};

export default class Cursor implements ICursor{
    private DOM: { el: HTMLElement };
    private bounds: DOMRect;
    private mouse: MousePosition = { x: 0, y: 0 };
    private renderedStyles: {
        [key: string]: {
            previous: number;
            current: number;
            amt: number;
        };
    };

    constructor(el: HTMLElement) {
        this.DOM = { el: el };
        this.DOM.el.style.opacity = '0';
        
        this.bounds = this.DOM.el.getBoundingClientRect();
        
        this.renderedStyles = {
            tx: { previous: 0, current: 0, amt: 0.2 },
            ty: { previous: 0, current: 0, amt: 0.2 },
            scale: { previous: 1, current: 1, amt: 0.2 },
            opacity: { previous: 1, current: 1, amt: 0.2 }
        };

        window.addEventListener('mousemove', ev => this.mouse = this.getMousePos(ev));

        this.onMouseMoveEv = () => {
            this.renderedStyles.tx.previous = this.renderedStyles.tx.current = this.mouse.x - this.bounds.width / 2;
            this.renderedStyles.ty.previous = this.renderedStyles.ty.current = this.mouse.y - this.bounds.height / 2;
            gsap.to(this.DOM.el, { duration: 0.4, ease: 'none', opacity: 1 });
            requestAnimationFrame(() => this.render());
            window.removeEventListener('mousemove', this.onMouseMoveEv);
        };
        window.addEventListener('mousemove', this.onMouseMoveEv);
    }

    private getMousePos(e: MouseEvent): MousePosition {
        return { x: e.clientX, y: e.clientY };
    }

    private lerp(a: number, b: number, n: number): number {
        return (1 - n) * a + n * b;
    }

    public enter(): void {
        this.renderedStyles.scale.current = 4;
        this.renderedStyles.opacity.current = 0.2;
    }

    public leave(): void {
        this.renderedStyles.scale.current = 1;
        this.renderedStyles.opacity.current = 1;
    }

    private render(): void {
        this.renderedStyles.tx.current = this.mouse.x - this.bounds.width / 2;
        this.renderedStyles.ty.current = this.mouse.y - this.bounds.height / 2;

        for (const key in this.renderedStyles) {
            if (this.renderedStyles.hasOwnProperty(key)) {
                this.renderedStyles[key].previous = this.lerp(this.renderedStyles[key].previous, this.renderedStyles[key].current, this.renderedStyles[key].amt);
            }
        }

        this.DOM.el.style.transform = `translateX(${this.renderedStyles.tx.previous}px) translateY(${this.renderedStyles.ty.previous}px) scale(${this.renderedStyles.scale.previous})`;
        this.DOM.el.style.opacity = `${this.renderedStyles.opacity.previous}`;

        requestAnimationFrame(() => this.render());
    }

    private onMouseMoveEv: () => void;
}
