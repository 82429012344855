import gsap from "gsap";

// Update the function to accept HTMLElement or an array of HTMLElements
export const createMagneticEffect = (elements: HTMLElement | HTMLElement[], strength: number): void => {
  // If elements is not an array, wrap it in an array to simplify processing
  const magnets = Array.isArray(elements) ? elements : [elements];

  magnets.forEach((magnet) => {
    magnet.addEventListener('mousemove', (event) => moveMagnet(event, magnet, strength));
    magnet.addEventListener('mouseout', (event) => {
      gsap.to(magnet, { duration: 1, x: 0, y: 0, ease: 'none' });
    });
  });

  function moveMagnet(event: MouseEvent, magnetButton: HTMLElement, strength: number): void {
    const bounding = magnetButton.getBoundingClientRect();

    gsap.to(magnetButton, {
      duration: 1,
      x: ((event.clientX - bounding.left) / magnetButton.offsetWidth - 0.5) * strength,
      y: ((event.clientY - bounding.top) / magnetButton.offsetHeight - 0.5) * strength,
      ease: "power4.out",
    });
  }
};
