export default class LoadState {
    private static instance: LoadState;
    public isFullyLoaded: boolean = false;
    private loadCompleteResolve: Function | null = null;
    private loadCompletePromise: Promise<void>;
  
    private constructor() {
      this.loadCompletePromise = new Promise<void>((resolve) => {
        this.loadCompleteResolve = resolve;
      });
    }
  
    public static getInstance(): LoadState {
      if (!LoadState.instance) {
        LoadState.instance = new LoadState();
      }
      return LoadState.instance;
    }
  
    public setFullyLoaded(value: boolean): void {
      this.isFullyLoaded = value;
      if (value && this.loadCompleteResolve) {
        this.loadCompleteResolve();
      }
    }
  
    public whenLoadComplete(): Promise<void> {
      return this.loadCompletePromise;
    }
  }
  