import gsap from 'gsap';

// Utility function to shuffle an array
function shuffleArray<T>(array: T[]): T[] {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]]; // Swap elements
  }
  return array;
}

// Adjusted function to animate text for all matching elements
export function animateTextForAll(selector: string): void {
  const elements = document.querySelectorAll(selector);
  elements.forEach((element) => {
    
    if (!element.textContent) {
      console.warn(`Element with selector "${selector}" not found or has no text.`);
      return;
    }
    const originalText = element.textContent; // Store original text
    element.setAttribute('data-original-text', originalText); // Store it in a data attribute

    // Split the text into individual characters
    const characters = element.textContent.split('').map(char => 
      char.trim() ? `<span style="opacity: 0; display: inline-block">${char}</span>` : '&nbsp;'
    ).join('');

    // Replace the element's content with spans wrapped around each character
    element.innerHTML = characters;

    // Select all span elements within the current element
    const spans = Array.from(element.querySelectorAll('span'));

    // Shuffle the spans to animate them in random order
    const shuffledSpans = shuffleArray(spans);

    // Use GSAP to animate the opacity of each span
    gsap.to(shuffledSpans, {
      opacity: 1,
      duration: 1,
      stagger: .3,
      ease: 'power2.inOut'
    });
  });
}

// Function to revert animated text back to its original state
export function revertAnimatedTextToOriginal(selector: string): void {
  const elements = document.querySelectorAll(selector);
  elements.forEach((element) => {
    const originalText = element.getAttribute('data-original-text'); // Retrieve the original text
    if (originalText) {
      element.innerHTML = originalText; // Replace the innerHTML with the original text
    }
  });
}
