interface Image {
  src: string;
  alt: string;
  class?: string; // class is optional
}

interface Section {
  type: string; // 'single-item' or 'gallery-item'
  images: Image[];
}

interface ILoader {
  setTotalImages(count: number): void;
  imageLoaded(): void;
}

function generateSections(sections: Section[], loader: ILoader): void {
  try {
     // Before starting to load images, calculate total images and inform the loader
    const totalImages = sections.reduce((acc, section) => acc + section.images.length, 0);
    loader.setTotalImages(totalImages);

    const mainElement = document.getElementById('main');
    const creditsSection = document.getElementById('credits');

    // Ensure both mainElement and creditsSection are found
    if (!mainElement || !creditsSection) {
      console.error("Main container or credits section not found");
      return;
    }

    // Collect promises for each image load
    let imageLoadPromises: Promise<void>[] = [];

    // Process the sections data
    sections.forEach((section: Section) => {
      const sectionContainer = document.createElement('div');
      sectionContainer.className = section.type;

      section.images.forEach((image: Image) => {
        const figure = document.createElement('figure');
        const picture = document.createElement('picture');
        const source = document.createElement('source');
        source.srcset = image.src;
        source.type = "image/webp";
        picture.appendChild(source); // Append source to picture

        const img = document.createElement('img');
        img.src = image.src;
        img.alt = image.alt;
        img.className = image.class || ''; // Apply class to img only

        // Add a promise for the load event of each image
        const loadPromise = new Promise<void>((resolve) => {
          img.onload = () => {
            resolve();
            loader.imageLoaded(); // Notify the loader that an image has loaded
          }
          img.onerror = () => {
            loader.imageLoaded();
            resolve(); // Resolve on error to not block the loader indefinitely
          }
        });
        imageLoadPromises.push(loadPromise);

        picture.appendChild(img); // Append img to picture
        figure.appendChild(picture);
        sectionContainer.appendChild(figure); // Append figure to the div container
      });

      const sectionElement = document.createElement('section');
      sectionElement.setAttribute('data-scroll-section', '');
      sectionElement.appendChild(sectionContainer);
      // Insert the new section before the credits section
      mainElement.insertBefore(sectionElement, creditsSection);
    });

    // Promise.all(imageLoadPromises).then(() => {
      // Hide the loader and show the main content
      // document.getElementById('loader')!.style.display = 'none';
      // document.getElementById('main')!.style.display = 'flex';
    // }).catch(error => console.error("Error loading images", error));

  } catch (error) {
    console.error("Failed to generate sections", error);
  }
}

export { generateSections };
