import gsap from 'gsap';

class Menu {
    private menu: HTMLElement;
    private author: HTMLElement;
    private darkModeToggle: HTMLInputElement;
    private isMenuOpen: boolean = false;
    private menuButton: HTMLElement | null;
    private menuContent: HTMLElement | null;
    private tl: gsap.core.Timeline;
    private play: boolean;
    private position: string;

    constructor(menu: HTMLElement, darkModeToggle: HTMLInputElement, author: HTMLElement, position: string) {
        this.menu = menu;
        this.author = author;
        this.darkModeToggle = darkModeToggle;
        this.position = position;
        this.menuButton = document.getElementById('menuButton');
        this.menuContent = document.getElementById('overlay-content');
        this.tl = gsap.timeline();
        this.init();
        this.play = true;

    }

    init() {
        if(this.menuButton) {
            this.menuButton.style.display = 'flex';
            this.menuButton.style.justifyContent = 'center';
            this.menuButton.style.opacity = '1';
            this.setupMenuToggle();
        }
    }
    
    setupMenuToggle() {
    

        this.menuButton?.addEventListener('click', () => {

            if(!this.play) {
                return;
            }
            const targetWidth = this.isMenuOpen ? '5%' : '100%';
            const left = this.isMenuOpen ? this.position : '70%';
            const right = this.isMenuOpen ? '0%' : '95%';
            const duration = this.isMenuOpen ? 0.5 : 0.7;
            const ease = "power3.inOut";

            this.tl
            .addLabel('startAnimations') // Animations will start at the same time
            .to(this.menuContent, { opacity: this.isMenuOpen ? 0 : 1, duration: this.isMenuOpen ? 0 : 0.7, delay: this.isMenuOpen ? 0 : .5, ease : ease }, 'startAnimations')
            .to(this.darkModeToggle, { left: left, ease: ease, duration: duration }, 'startAnimations')
            .to(this.author, { right: right, ease: ease, duration: duration }, 'startAnimations')
            .call(this.changeMenuText.bind(this), [{ ease: ease, duration: duration }], 'startAnimations')
            .to(this.menu, {
                ease : ease,
                duration : duration,
                width: targetWidth,
                onUpdate: () => {
                    this.play = false;
                },
                onComplete: () => {
                    this.isMenuOpen = !this.isMenuOpen;
                    this.play = true;
                    // this.changeMenuText();
                    // console.log(`Menu is ${this.isMenuOpen ? "open" : "closed"}`);
                }
            }, 'startAnimations')
        });
    }

    changeMenuText() {
        const text = this.isMenuOpen ? "Ouvrir menu" : "Fermer menu"
        if(this.menuButton){
            this.menuButton.innerHTML = text;
        }        
    }
}

export default Menu;
