

const sectionsData = [
    {
      "type": "single-item",
      "images": [
        {
          "src": "https://theanalogproject.s3.eu-west-3.amazonaws.com/image-1.webp",
          "alt": "People walking on the streets of Oaxaca",
          "class": "c"
        }
      ]
    },
    {
      "type": "gallery-item",
      "images": [
        {
          "src": "https://theanalogproject.s3.eu-west-3.amazonaws.com/image-2.webp",
          "alt": "A young man on a bus in Sucre",
          "class": "b"
        },
        {
          "src": "https://theanalogproject.s3.eu-west-3.amazonaws.com/image-3.webp",
          "alt": "Three sisters playing in San Sebastian",
          "class": "b"
        }
      ]
    },
    {
        "type": "single-item",
        "images": [
          {
            "src": "https://theanalogproject.s3.eu-west-3.amazonaws.com/image-4.webp",
            "alt": "People in La Concha beach, San Sebastian",
            "class": "c"
          }
        ]
    },
    {
        "type": "single-item",
        "images": [
          {
            "src": "https://theanalogproject.s3.eu-west-3.amazonaws.com/image-5.webp",
            "alt": "A child playing on the sand in San Sebastian",
            "class": "c"
          }
        ]
    },
    {
        "type": "gallery-item",
        "images": [
          {
            "src": "https://theanalogproject.s3.eu-west-3.amazonaws.com/image-6.webp",
            "alt": "Tourists taking a picture in Hierve el Agua",
            "class": "a"
          },
          {
            "src": "https://theanalogproject.s3.eu-west-3.amazonaws.com/image-7.webp",
            "alt": "Tourists staring at the ocean in Playa del Carmen",
            "class": "a"
          }
        ]
      },
      {
        "type": "single-item",
        "images": [
          {
            "src": "https://theanalogproject.s3.eu-west-3.amazonaws.com/image-8.webp",
            "alt": "Water flowing in Nicaragua",
            "class": "c"
          }
        ]
    },
    {
        "type": "gallery-item",
        "images": [
          {
            "src": "https://theanalogproject.s3.eu-west-3.amazonaws.com/image-9.webp",
            "alt": "A woman from behind in Bayonne",
            "class": "b"
          },
          {
            "src": "https://theanalogproject.s3.eu-west-3.amazonaws.com/image-10.webp",
            "alt": "Smoke in the Isla del Sol",
            "class": "b"
          }
        ]
      },
      {
        "type": "single-item",
        "images": [
          {
            "src": "https://theanalogproject.s3.eu-west-3.amazonaws.com/image-11.webp",
            "alt": "Seagulls in the sky of Paris",
            "class": "c"
          }
        ]
    },
    {
        "type": "single-item",
        "images": [
          {
            "src": "https://theanalogproject.s3.eu-west-3.amazonaws.com/image-12.webp",
            "alt": "Some silhouette walking in the distance in Tarnos",
            "class": "c"
          }
        ]
    },
    {
        "type": "single-item",
        "images": [
          {
            "src": "https://theanalogproject.s3.eu-west-3.amazonaws.com/image-13.webp",
            "alt": "Another silhouette walking in Bischwiller",
            "class": "c"
          }
        ]
    },
    {
        "type": "gallery-item",
        "images": [
          {
            "src": "https://theanalogproject.s3.eu-west-3.amazonaws.com/image-14.webp",
            "alt": "Walls of Bischwiller",
            "class": "d"
          },
          {
            "src": "https://theanalogproject.s3.eu-west-3.amazonaws.com/image-15.webp",
            "alt": "Shadows in the streets of Bayonne",
            "class": "b"
          }
        ]
      },
      {
        "type": "gallery-item",
        "images": [
          {
            "src": "https://theanalogproject.s3.eu-west-3.amazonaws.com/image-16.webp",
            "alt": "A man staring at the ground in Bayonne",
            "class": "a"
          },
          {
            "src": "https://theanalogproject.s3.eu-west-3.amazonaws.com/image-17.webp",
            "alt": "A fisherman from behind in Biarritz",
            "class": "b"
          }
        ]
      },
      {
        "type": "gallery-item",
        "images": [
          {
            "src": "https://theanalogproject.s3.eu-west-3.amazonaws.com/image-18.webp",
            "alt": "A man's shirt hanging in the street of Mexico",
            "class": "b"
          },
          {
            "src": "https://theanalogproject.s3.eu-west-3.amazonaws.com/image-19.webp",
            "alt": "A wedding dress in a shop window in the streets of Mexico",
            "class": "b"
          }
        ]
      },
      {
        "type": "single-item",
        "images": [
          {
            "src": "https://theanalogproject.s3.eu-west-3.amazonaws.com/image-20.webp",
            "alt": "A statue representing a hand in French Brittany",
            "class": "b"
          }
        ]
    },
    {
        "type": "single-item",
        "images": [
          {
            "src": "https://theanalogproject.s3.eu-west-3.amazonaws.com/image-21.webp",
            "alt": "A shadow walking in Copacobana",
            "class": "c"
          }
        ]
      },
      {
        "type": "gallery-item",
        "images": [
          {
            "src": "https://theanalogproject.s3.eu-west-3.amazonaws.com/image-22-2.webp",
            "alt": "Woman's shoes in the streets of San Cristobal de Las Casas",
            "class": "b"
          },
          {
            "src": "https://theanalogproject.s3.eu-west-3.amazonaws.com/image-23.webp",
            "alt": "A plane in the sky of Anglet",
            "class": "b"
          },
        ]
      },
      {
        "type": "single-item",
        "images": [
          {
            "src": "https://theanalogproject.s3.eu-west-3.amazonaws.com/image-24.webp",
            "alt": "A woman silhouette hiding in the heights of Peru",
            "class": "c"
          }
        ]
    },
    {
        "type": "single-item",
        "images": [
          {
            "src": "https://theanalogproject.s3.eu-west-3.amazonaws.com/image-25.webp",
            "alt": "A statue shadow in the streets of Oaxaca",
            "class": "e"
          }
        ]
    }

  ]

  export default sectionsData;
  