export function isMobile() {
    const userAgent = navigator.userAgent.toLowerCase();
    const isMobileUserAgent = /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/.test(userAgent);
    const viewportWidth = window.innerWidth;
    const mobileWidthThreshold = 767; 

    const viewportHeigth = window.innerHeight;
    const mobileHeightThreshold = 600;

    // console.log("isMobileOrTablet", isMobileUserAgent, viewportWidth, mobileWidthThreshold, viewportHeigth, mobileHeightThreshold)
    
    return isMobileUserAgent || viewportWidth <= mobileWidthThreshold || viewportHeigth <= mobileHeightThreshold;

  }
  export function displayMobileMessage() {
    const h1 = document.createElement('h1');
    h1.textContent = "Moderne Solitude";
  
    const h2 = document.createElement('h2');
    h2.textContent = "par Ismaël Perez";
  
    const container = document.createElement('div');
    container.classList.add('container-mobile');
  
    const paragraphEN = document.createElement('p');
    const paragraphFR = document.createElement('p');

    const textFR = "Pour une meilleure expérience visuelle, veuillez visiter le site sur un ordinateur.";  
    paragraphFR.textContent = textFR;

    const textEN = "For a better experience, please visit the website on a computer.";  
    paragraphEN.textContent = textEN;

    const paragraphs = document.createElement('div');
    paragraphs.classList.add('paragraphs');
    paragraphs.appendChild(paragraphEN);
    paragraphs.appendChild(paragraphFR);


    const title = document.createElement('div');
    title.classList.add('title');
    title.appendChild(h1);
    title.appendChild(h2);

    // Adding elements to the container
    container.appendChild(title);
    container.appendChild(paragraphs);
    
    // Clear the body's content
    document.body.innerHTML = '';
    // Append the container to the body
    document.body.appendChild(container);
  
    // Prevent further script execution or consider redirecting the user.
  }
  
  