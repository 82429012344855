// index.ts
import Loader from './Components/Loader';
import { isMobile, displayMobileMessage } from './utils/detectMobileDevice';
import Cursor from './utils/Cursor';
import dataSections from './assets/data/dataSections';
import { generateSections } from './utils/getData';
import Intro from './Main/Intro';
import { initializeThemeToggle } from './utils/useDarkMode';

window.addEventListener('wheel', e => e.preventDefault(), { passive: false });

// Initialize the cursor
const cursorElement = document.querySelector<HTMLElement>('.cursor');

if (isMobile()) {
  displayMobileMessage();
} 
else {
  // Initialize the app
  document.addEventListener('DOMContentLoaded', () => {
    const loader = new Loader();
    const cursor = new Cursor(cursorElement as HTMLElement);
    generateSections(dataSections, loader);
    initializeThemeToggle();
    
    // Set the callback
    loader.setOnLoadComplete(() => {
      new Intro(cursor);
    });
  })
}
