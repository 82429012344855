// Intro.ts
import gsap from 'gsap';
import { animateTextForAll, revertAnimatedTextToOriginal } from '../utils/animateText';
import { createMagneticEffect } from '../utils/magneticEffect';
import Menu from '../Components/Menu';
import DragScroller from '../utils/DragScroller';
import ICursor from '../types/ICursor';
import LoadState from '../Components/LoadState';

class Intro {
  private cursor: ICursor;
  private scrollContainer: HTMLElement;
  private figureContainer: HTMLElement | null;
  private introTexts: HTMLCollectionOf<Element>;
  private titles: NodeListOf<Element>;
  private darkModeToggle: HTMLInputElement;
  private tl: gsap.core.Timeline;
  private overlay : HTMLElement = document.getElementById('overlay') as HTMLElement;
  private author : HTMLElement = document.getElementById('author') as HTMLElement;
  private main : HTMLElement = document.getElementById('main') as HTMLElement;
  private gsapCommonOptions = { ease: 'power2.inOut', duration: 0.7 };

  private togglePosition: string;
  private textElement?: HTMLDivElement;

  constructor(cursor: ICursor) {

    this.cursor = cursor;
    this.figureContainer = document.getElementById('figureContainer');
    this.introTexts = document.getElementsByClassName('introText');
    this.titles = document.querySelectorAll('h1');
    this.darkModeToggle = document.getElementById('darkModeToggle') as HTMLInputElement;
    this.togglePosition = `calc(50% - ${this.darkModeToggle.offsetWidth / 2}px)`;
    this.tl = gsap.timeline();
    this.scrollContainer = document.getElementById('main') as HTMLElement;
    this.author = document.getElementById('author') as HTMLElement;

    // Disable scroll on touch devices
    this.isTouchSupported() ? this.disableScroll() : this.initCursorInteractions();

    this.createAndAnimateText();
    this.introTextAnimation(); 
  }

  private disableScroll(): void {
    window.addEventListener('touchmove', this.preventScroll, { passive: false });
  
  }

  private preventScroll(e: TouchEvent): void {
    e.preventDefault();
  }

  private enableScroll(): void {
    window.removeEventListener('touchmove', this.preventScroll);
  }

    private createAndAnimateText(): void {
      // Create the text element
      this.textElement = document.createElement('div');
      this.textElement.id = 'scrollText';
      this.textElement.textContent = 'Scrollez pour commencer';
      
      // Style the text element
      Object.assign(this.textElement.style, {
          position: 'absolute',
          top: '2em',
          left: '50%',
          transform: 'translateX(-50%)',
          opacity: '0', // Start invisible
      });

      // Append the text element to the body
      document.body.appendChild(this.textElement);

      // Animate the text element after a 3-second delay
      gsap.to(this.textElement, {delay: 3, duration: 1, opacity: 1});
    }

    private isTouchSupported(): boolean {
      return 'ontouchstart' in window || navigator.maxTouchPoints > 0;
    }
  
    // Touch or scroll to init app
    private initApp(): void {
      const init = () => {
        this.initAnimations();
    
        if (this.isTouchSupported()) {
          document.body.removeEventListener('touchmove', init);
        }
        document.body.removeEventListener('wheel', init);
      };
    
      if (this.isTouchSupported()) {
        document.body.addEventListener('touchmove', init, { passive: true });
      }
      document.body.addEventListener('wheel', init);
    }    

  // Intro text animation playing
  private introTextAnimation(): void {
    const h1 = document.querySelectorAll('.introContainer h1');
    this.main.style.display = 'flex';

    this.tl
    .to(this.figureContainer, { 
      opacity: 1,
      duration: .7,
      ease: 'power3.inOut',
      onComplete: () => {
        h1.forEach((h1: Element) => {
          (h1 as HTMLElement).style.display = 'flex';
        })
        animateTextForAll('.introContainer h1');
        this.initApp();
      }
    })
  }

  // Init cursor interactions if user is on Desktop (only)
  private initCursorInteractions(): void {
    const interactiveElements = document.querySelectorAll('button');
    interactiveElements.forEach(element => {
      element.addEventListener('mouseenter', () => {
        this.cursor.enter()
      });
      element.addEventListener('mouseleave', () => this.cursor.leave());
    });
  }

  private setTogglePosition(): void{
    this.darkModeToggle.style.left = this.togglePosition;
    window.addEventListener('resize', () => {
      const newPos = `calc(50% - ${this.darkModeToggle.offsetWidth / 2}px)`;
      this.darkModeToggle.style.left = newPos;
    });
  }

  // First elements animations
  private initAnimations(): void {
    if (!this.figureContainer) {
      console.warn('Figure container not found');
      return;
    }
    this.setTogglePosition();
    
    this.tl
      .to(this.textElement!, {
        duration: 1, 
        opacity: 0,
        onComplete: () => {
          this.textElement?.remove();
        }
      })
      .to(this.figureContainer, { ...this.gsapCommonOptions, width: '100%' })
      .to(this.figureContainer, { ...this.gsapCommonOptions, height: '70%' })
      .to(this.author, { ...this.gsapCommonOptions, width: '5%' })
      .to(this.darkModeToggle, { ...this.gsapCommonOptions, opacity: '100%', onComplete: () => {
          createMagneticEffect(this.darkModeToggle, (50));
          this.initUserActions();
      }})
  }

  // Init animations and user actions on second scroll or touch
  private initUserActions(): void {
    const initUser = () => {
      this.tl
        .addLabel('startAnimations')
        .to(this.titles[0], { ...this.gsapCommonOptions, y: '-100%' }, 'startAnimations')
        .to(this.titles[1], { ...this.gsapCommonOptions, y: '100%' }, 'startAnimations')
        .to(this.figureContainer, { ...this.gsapCommonOptions, height: '100%', onComplete: () => {
          this.tl.kill(); 
          this.adjustTitleSizeAndMove();
          this.initMenu();
          this.animateTitles();
          this.initHorizontalScroll();
        }});
  
      document.body.removeEventListener('wheel', initUser);
      if (this.isTouchSupported()) {
        document.body.removeEventListener('touchmove', initUser);
      }
    };
  
    if (this.isTouchSupported()) {
      document.body.addEventListener('touchmove', initUser, { passive: true });
    }
    document.body.addEventListener('wheel', initUser);
  }
  
  // Move and append titles to the overlay
  private adjustTitleSizeAndMove(): void {

    revertAnimatedTextToOriginal('.introContainer h1');

    Array.from(this.introTexts).forEach((introText, index) => {
      this.overlay.appendChild(introText);
      introText.classList.remove('initial');

      if (index % 2 === 0) {
        introText.classList.add('bottom');
      } else {
        introText.classList.add('top');
      }
    });
  }

  // Animate titles after appending them to the overlay
  private animateTitles(): void {
    document.querySelectorAll('h1').forEach((title: HTMLElement) => {
      gsap.to(title, { ...this.gsapCommonOptions, y: 0});
    });
  }

  // Init the Menu class
  private initMenu(): void {
    new Menu(this.overlay, this.darkModeToggle, this.author, this.togglePosition); // Init the Menu class
  }

  // Init horizontal scroll with GSAP ScrollTrigger on Desktop (only)
  private initHorizontalScroll(): void {
    const loadState = LoadState.getInstance();

    if (!this.isTouchSupported()) {
    //   loadState.whenLoadComplete().then(() => {
    //     new DragScroller(this.scrollContainer);
    //   }).catch((error: any) => {
    //     console.error("Error waiting for load completion:", error);
    //   });
    // }
    new DragScroller(this.scrollContainer);
    }
    else{
      this.enableScroll();
    }
  }
  
  
}
  

export default Intro;
